<template>
	<div class="tw-bg-FD tw-relative">
		<h4 class="tw-font-semibold tw-text-22 tw-leading-4">
			{{ $t('contactUs') }}
		</h4>
		<p class="tw-text-black tw-text-opacity-50 tw-mt-4">
			{{ $t('contact-us-subtitle') }}
		</p>
		<div class="tw-mt-8 lg:tw-mt-24">
			<v-form ref="form" lazy-validation @submit.prevent="handleContactUs">
				<v-text-field
					solo
					flat
					:rules="[rules.required('Name')]"
					class="user-input"
					background-color="white"
					hide-details="auto"
					:placeholder="$t('message-title')"
					v-model="contact.title"
					:height="$vuetify.breakpoint.lgAndUp ? 60 : ''"
				/>
				<!-- <v-textarea
					solo
					background-color="white"
					:rules="[rules.required('Name')]"
					:rows="6"
					hide-details="auto"
					placeholder="Message Description"
					v-model="contact.description"
					:height="$vuetify.breakpoint.lgAndUp ? 60 : ''"
				/> -->
				<v-textarea
					background-color="white"
					solo
					:rules="[rules.required('Description')]"
					auto-grow
					rows="7"
					v-model="contact.description"
					class="contactus tw-mt-8"
					:placeholder="$t('message-description')"
				></v-textarea>
			</v-form>
			<v-btn
				:height="$vuetify.breakpoint.lgAndUp ? '60px' : ''"
				dark
				type="submit"
				block
				:loading="loading"
				class="tw-rounded-lg tw-mt-6 tw-normal-case tw-mb-20"
				color="#722432"
				@click="handleContactUs"
			>
				{{ $t('send-message') }}</v-btn
			>
		</div>
		<!-- footer-contactus -->
		<div
			class="tw-flex tw-items-center tw-justify-between tw-w-full 2xl:tw-mt-40"
		>
			<div>
				<span class="tw-text-xl tw-font-semibold">{{
					$t('our-email')
				}}</span>
				<br />
				<span class="tw-text-xl tw-font-light tw-text-bcb tw-mt-2">
					Info@Bidto.com
				</span>
			</div>
			<!-- <div
				class="tw-flex tw-space-s-2 lg:tw-space-s-14 tw-items-center tw-mt-2"
			>
				<v-icon color="blue">
					mdi-google
				</v-icon>
				<v-icon color="blue">
					mdi-twitter
				</v-icon>
				<v-icon color="blue">
					mdi-facebook
				</v-icon>
			</div> -->
		</div>
	</div>
</template>

<script>
import { mapActions } from 'vuex'
import { createFormMixin } from '@/mixins/form-mixin'

export default {
	name: 'ContactUS',
	mixins: [
		createFormMixin({
			rules: ['required', 'email', 'password'],
		}),
	],
	data() {
		return {
			loading: false,
			contact: {
				title: '',
				description: '',
			},
		}
	},
	computed: {},
	methods: {
		...mapActions('auth', ['contactUs']),
		async handleContactUs() {
			if (!this.$refs.form.validate()) {
				this.$utils.scrollIntoError(this)
				return
			}

			this.loading = true
			let [err] = await this.contactUs(this.contact)
			if (!err) {
				this.loading = false
				this.$refs.form.reset()
				this.$toast.success(this.$t('message-sent'))
			} else {
            this.$utils.scrollIntoError(this)
				this.$toast.error(this.$t('something-went-wrong'))
				this.loading = false
			}
		},
	},
}
</script>

<style>
.contactus.v-text-field.v-text-field--solo:not(.v-text-field--solo-flat)
	> .v-input__control
	> .v-input__slot {
	box-shadow: none !important;
}
</style>
